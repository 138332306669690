.Contacts {
  &-header {
    z-index: 3;
    width: 100%;
    align-items: center;
    display: flex;
    background-color: #0e1b2c;
    &-content {
      z-index: 3;
      padding: 32px 80px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 32px;
        text-align: left;
        color: white;
        font-family: 'GraphikSemibold';
      }
    }
    &-contactContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &-contact {
        flex-direction: row;
        display: flex;
        align-items: center;
        margin-left: 16px;
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        &-text {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: left;
          &-1 {
            text-align: left;
            font-family: 'GraphikSemibold' !important;
            font-size: 20px !important;
            color: white !important;
          }
          &-2-container {
            display: flex;
            flex-direction: column;
            align-items: left;
            span {
              text-align: left;
              font-family: 'GilroyRegular' !important;
              font-size: 16px !important;
              color: rgba(255, 255, 255, 0.9) !important;
            }
          }
        }
      }
    }
    @media (max-width: 992px) {
      justify-content: center;
      &-content {
        padding: 24px 8px;
        align-items: center;
        display: flex;
        flex-direction: column;
        span {
          font-size: 24px;
          color: white;
          text-align: center;
        }
      }
      &-contactContainer {
        flex-direction: column;
        margin-top: 16px;
        &-contact {
          flex-direction: column;
          margin-left: 0px;
          &-text {
            margin-top: 8px;
            margin-left: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &-1 {
              text-align: center;
              font-family: 'GraphikSemibold' !important;
              font-size: 18px !important;
              color: white !important;
            }
            &-2-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-bottom: 16px;
              span {
                text-align: center;
                font-family: 'GilroyRegular' !important;
                font-size: 14px !important;
                color: rgba(255, 255, 255, 0.9) !important;
              }
            }
          }
        }
      }
    }
  }
}
