.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  top: 50px;

  @media (min-width: 992px) {
    top: 25px;
  }
}

.navbar {
  background-color: transparent;
  transition: all 0.5s ease-in-out;

  &-overlay {
    position: absolute;
    top: -80px;
    right: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    pointer-events: none;
    height: 290px;
    background-image: linear-gradient(
      -180deg,
      rgba(14, 14, 14, 0.9) 0%,
      rgba(14, 14, 14, 0.76) 32%,
      rgba(43, 43, 43, 0) 100%
    );
  }

  &-logoContainer {
    position: absolute;
    align-self: center;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    span {
      font-size: 20px;
      color: white;
      font-family: 'CopperplateBold';
      letter-spacing: 1px;
    }
    &-logo {
      background-image: url('https://raw.githubusercontent.com/alisherakb/tinder-survey/master/logo.svg');
      background-repeat: no-repeat;
      align-self: center;
      width: 76px;
      height: 35px;
    }
  }

  > .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-collapse {
    height: auto !important;

    @media (max-width: 991px) {
      background: $color-black;
      padding: 20px;
      padding-top: 83px;
      position: fixed;
      top: 0;
      right: 0;
      height: auto;
      width: 0%;
      z-index: 1;
      opacity: 0;
      transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
      transition-delay: 100ms;

      &.show {
        width: 250px;
        opacity: 1;
      }
    }
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }

  .navbar-toggler {
    height: 16px;
    width: 23px;
    cursor: pointer;
    margin: 8px 16px;
    border: 0;
    background: transparent;
    outline: 0;
    box-shadow: none;
    z-index: 9;
    position: relative;

    > span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      // background-color: #19212d !important;
      // background-color: white !important;
      background: #fff none repeat scroll 0% 0%;
      opacity: 1;
      top: 0;
      left: 0px;
      transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
      transition-delay: 100ms;
      transform: rotate(0deg);

      &:nth-child(1) {
        transform: rotate(135deg);
        margin-top: 7px;
      }

      &:nth-child(2) {
        opacity: 0;
        left: -23px * 3;
      }

      &:nth-child(3) {
        transform: rotate(-135deg);
        margin-top: 7px;
      }
    }

    &.collapsed {
      span {
        background-color: white;
        &:nth-child(1) {
          margin-top: 0px;
          transform: rotate(0deg);
        }

        &:nth-child(2) {
          margin-top: 7px;
          left: 0px;
          opacity: 1;
        }

        &:nth-child(3) {
          margin-top: 14px;
          transform: rotate(0deg);
        }
      }
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  .nav-link {
    font-size: 14px !important;
    margin: 0;
    line-height: normal;
    color: #fff;
    position: relative;
    font-family: 'GilroyLight';

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      left: 0;
      background-color: $color-red;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover,
    &.active {
      &:after {
        visibility: visible;
        transform: scaleX(1);
      }
    }

    @media (max-width: 991px) {
      &:hover,
      &.active {
        color: #fff;
      }

      padding: 15px 0;
      border-bottom: 1px solid #19212d;
      @include font-size(18);
      font-weight: 300;
    }

    @media (min-width: 992px) {
      margin-right: 20px;
      outline: none;
      :hover {
        outline: none;
      }

      &:after {
        height: 3px;
        bottom: -3px;
      }
    }
  }

  &.sticky {
    top: 0;
    background-color: #fff;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.14);
    padding: 15px 0;
    .navbar-logoContainer {
      span {
        color: #0e1b2c;
      }
      &-logo {
        background-image: url('https://raw.githubusercontent.com/alisherakb/tinder-survey/master/dark_logo.svg');
        background-repeat: no-repeat;
        align-self: center;
        width: 76px;
        height: 35px;
      }
    }

    .navbar-collapse {
      @media (max-width: 991px) {
        padding-top: 63px;
      }
    }

    .navbar-toggler {
      > span {
        background-color: $color-black;
      }
    }
  }

  @media (min-width: 992px) {
    flex-wrap: nowrap;
    padding: 25px 0;

    &.sticky {
      padding: 19px 0px 18px 0;
      top: 0;
      .navbar-toggler {
        span {
          background-color: white !important;
          margin-top: 40px;
        }
      }
      .navbar-logoContainer {
        span {
          color: #0e1b2c;
        }
        &-logo {
          background-image: url('https://raw.githubusercontent.com/alisherakb/tinder-survey/master/dark_logo.svg');
          background-repeat: no-repeat;
          align-self: center;
          width: 76px;
          height: 35px;
        }
      }
      .nav-link {
        color: #334357;
        opacity: 0.8;

        &:hover,
        &.active {
          color: $color-red;
        }
      }
    }
  }
}
