@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'pages/header';
@import 'pages/home';
@import 'pages/about';
@import 'pages/services';
@import 'pages/partners.scss';
@import 'pages/careers.scss';
@import 'pages/contacts.scss';
@import 'pages/footer.scss';
@import './fonts';

section {
  + section {
    + section {
      background-color: #fff;
      color: $color-red;
      background-color: $color-red;
      color: #fff;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
