.Careers {
  &-header {
    z-index: 3;
    height: 180px;
    width: 100%;
    align-items: center;
    display: flex;
    background-color: #0e1b2c;
    &-content {
      z-index: 3;
      padding-left: 80px;
      span {
        font-size: 48px;
        color: white;
        font-family: 'GraphikSemibold';
        text-align: center;
      }
      div {
        width: 120px;
        height: 12px;
        background-color: #3283e6;
      }
    }
    @media (max-width: 992px) {
      justify-content: center;
      padding: 24px 0px;
      height: 140px;
      &-content {
        padding-left: 0px;
        align-items: center;
        display: flex;
        flex-direction: column;
        span {
          font-size: 32px;
          color: white;
          text-align: center;
        }
        div {
          width: 120px;
          height: 8px;
          background-color: #3283e6;
        }
      }
    }
  }
  &-body {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-top: 24px;
    &-h1 {
      font-size: 24px;
      font-family: 'GraphikSemibold';
      color: #0e1b2c;
      text-align: center;
      width: 550px;
    }
    &-h2 {
      font-size: 18px;
      font-family: 'GraphikLight';
      color: #0e1b2c;
      text-align: center;
    }
    &-main {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 40px 0px;
      &-success {
        background-color: #e8f1fc;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 24px;
        border-radius: 4px;
        img {
          width: 48px !important;
          margin-right: 0px !important;
          height: 48px !important;
          margin-bottom: 12px;
          object-fit: contain;
        }
        span {
          font-size: 16px;
          font-family: 'GilroyMedium';
          color: #3283e6;
        }
      }
      img {
        width: 750px;
        height: 450px;
        margin-right: 60px;
        object-fit: cover;
      }
      &-formsContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        &-errorMessage {
          margin-top: 8px;
          span {
            color: rgb(206, 58, 58);
            font-size: 16px;
            font-family: 'GilroyRegular';
          }
        }
        input {
          font-size: 18px;
          border-top: 0px;
          border-left: 0px;
          width: 360px;
          border-right: 0px;
          border-bottom: 1px solid;
          border-color: #0a2246;
          font-family: 'GilroyLight';
        }
        input:focus {
          outline: none;
          border-color: #3283e6;
        }
        input:hover {
          outline: none;
        }
        button {
          background-color: #3283e6;
          align-items: center;
          justify-content: center;
          display: flex;
          margin-top: 28px;
          outline: none;
          border: 0px solid;
          span {
            padding: 16px 150px;
            color: white;
            font-size: 20px;
            font-family: 'GraphikMedium';
          }
        }
        &-drop {
          margin-top: 24px;
          align-items: center;
          justify-content: center;
          width: 100%;
          display: flex;
          height: 120px;
          border: 1px solid;
          border-radius: 4px;
          border-style: dashed;
          flex-direction: column;
          input:focus {
            outline: none !important;
          }
          input:active {
            outline: none !important;
          }
          img {
            width: 48px;
            height: 36px;
            object-fit: contain;
            margin-right: 0px;
            margin-bottom: 8px;
          }
          p {
            font-family: 'GilroyRegular';
            font-size: 14px;
            width: 60%;
            margin-block-end: 0px;
            text-overflow: ellipsis;
            overflow: hidden !important;
            margin-block-start: 0px;
          }
        }
      }
    }
    @media (max-width: 992px) {
      &-h1 {
        width: auto;
        font-size: 20px;
      }
      &-h2 {
        font-size: 16px;
      }
      &-main {
        flex-direction: column;
        &-formsContainer {
          padding: 0px 16px;
          &-drop {
            img {
              width: 64px !important;
              height: 64px !important;
            }
          }
        }
        img {
          width: 80%;
          height: 50%;
          margin-left: 0px;
          margin-right: 0px;
          object-fit: contain;
        }
      }
    }
  }
}
