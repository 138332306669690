@font-face {
  font-family: 'GraphikBold';
  src: url('/fonts/Graphik-Bold.ttf');
}
@font-face {
  font-family: 'GraphikSemiBold';
  src: url('/fonts/Graphik-Semibold.ttf');
}
@font-face {
  font-family: 'GraphikMedium';
  src: url('/fonts/Graphik-Medium.ttf');
}
@font-face {
  font-family: 'GraphikRegular';
  src: url('/fonts/Graphik-Regular.ttf');
}
@font-face {
  font-family: 'GraphikLight';
  src: url('/fonts/Graphik-Light.ttf');
}
@font-face {
  font-family: 'GilroySemiBold';
  src: url('/fonts/Gilroy-SemiBold.ttf');
}
@font-face {
  font-family: 'GilroyRegular';
  src: url('/fonts/Gilroy-Regular.ttf');
}
@font-face {
  font-family: 'GilroyLight';
  src: url('/fonts/Gilroy-Regular.ttf');
}
@font-face {
  font-family: 'GilroyMedium';
  src: url('/fonts/Gilroy-Medium.ttf');
}
@font-face {
  font-family: 'CopperplateBold';
  src: url('/fonts/Copperplate-Bold.ttf');
}
