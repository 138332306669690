.Home {
  &-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  &-overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(7, 8, 9, 0.2);
    width: 100%;
    height: 100%;
  }
}
